.form-mclogo{
  border-radius: 5px;
  height: 40px;
  width: 40px;
  padding: 0;
   margin: 0;
  object-fit: contain;
 
}
.form-tsheet-Create{
  width: 100%;
  /* margin: 0 auto; */
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
  
}

.form-tsheet-Edit
{
  width: 60%;
  margin: 0 auto;
  padding: 10px;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
}

.form-tsheet-View{
  width: 50%;
  margin: 0 auto;
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
}

.topic-head{
  font-weight: bold;
  font-family: "Calibri";
  padding: 10px;

}

.form-tsheet-Edit input[type="text"],
.form-tsheet-Edit input[type="number"],
.form-tsheet-Create input[type="text"],
.form-tsheet-Create input[type="number"] {
margin: 2px;
display: block;
width: 95%;
height: 30px;
padding: 10px;
font-size: 16px;
border: none;
border-radius: 5px;
background-color: #fff;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
box-sizing: border-box;
}
.form-tsheet-Create textarea{
  margin: 5px;
  padding: 10px;
  display: block;
  width: 95%;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}