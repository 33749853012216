.layout_main{
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
}

.layout_sidebar_container{
    width: 250px;
    height: 100%;
}
.layout_sidebar_container.collapsed {
    width: 5px;
  }

  .layout_sidebar_container {
    width: 250px;
    height: 100%;
    transition: width 0.8s ease; 
  }
  

.layout_right_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-left: 40px;
}

.layout_main_area_container{

    height: 100%;
    flex-grow: 1;
    margin: 80px 20px 20px 20px;
    /* padding: 0px 90px; */
    overflow-y: auto;
}