/* .popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-inner {
  position: relative;
  padding: auto;
}
.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: #bbb8b4 !important; 
 } */
.popupPdf {
  position: fixed;
  top: 0;
  left: 0;
  /* margin-top: 50px; */
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.popup-inner-pdf {
  position: relative;
  padding: auto;
  width: 70%;
  height: 100%;
}
.popup-inner-pdf .close-btn-pdf {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: #5b3a0f !important;
}

