.form-mclogo{
  border-radius: 5px;
  height: 40px;
  width: 40px;
  padding: 0;
   margin: 0;
  object-fit: contain;
 
}
.form-proskill-Create{
  width: 90%;
  /* margin: 0 auto; */
  /* padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px; */
  /* box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2); */
  /* background-color: 0px 0px 0px ; */
  
}

.form-proskill-Edit
{
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
}

.form-proskill-View{
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f2f2f2;

  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
}

.topic-head{
  font-weight: bold;
  font-family: "Calibri";
  padding: 10px;

}

.form-proskill-Edit input[type="text"],
.form-proskill-Edit input[type="number"],
.form-proskill-Create input[type="text"],
.form-proskill-Create input[type="number"],
.form-proskill-Create textarea {
margin: 2px;
display: block;
width: 99%;
height: 30px;
padding: 10px;
font-size: 16px;
border: none;
border-radius: 5px;
background-color: #fff;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
box-sizing: border-box;
}
select {
margin: 2px;
display: block;
width: 95%;
height: 30px;
/* padding: 10px; */
font-size: 16px;
border: none;
border-radius: 5px;
background-color: #fac364;
box-shadow: 0px 0px 5px rgba(184, 189, 141, 0.1);
box-sizing: border-box;
}

.table-cell,
#proskillcss-view,
#proskillcss-edit {
font-size: 12px;
border-spacing: 0;
padding-top: 0px;
padding-left: 0px;
padding-bottom: 0px;
padding: 5px;
margin: 0;
width: 100px;
white-space: nowrap;
font-weight: bold;
}

.mold-checkbox {
  margin-top: 10px;
  width: 30px;
}

.img{
   display:block;
    margin: 0 auto;
    width: 200px;
     height: 200px; 
}
.cell {
  height: 50%;
  white-space: nowrap;
  padding: 10px;
}
.pro_skill_img{
  margin-right: 5px;
    width: 40px;
    height: 40px;
}
.phototd{
  text-align: center;
}
.photo{
    width: 150px;
    height: 150px;  
}