.smp_report_label{
    display: block !important;
    /* margin-bottom: 5px; */
    font-weight: bold !important;
    font-size: 16px;
    text-align: center;
    color: rgb(16, 4, 194);
  }
  .form-container5 {
    max-width: 1800px;
    margin: 0 auto;
    padding: 5px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .smp_report_set_value{
    /* display: block !important; */
    /* margin-bottom: 5px; */
    font-weight: bold !important;
    /* font-size: 12px; */
    /* text-align: center;  */
    color: rgb(189, 19, 19);
  }
  .cell {
    height: 50%;
    white-space: nowrap;
    padding: 10px;
  }