.Form{
    max-width: 100%;
    padding: 5px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
    background-color: 0px 0px 0px ;
    
}
th{
    background-color: #96d4d4;
}
