.DoubleGrid{
    display: grid;
    padding: 10px;
    grid-template-columns: minmax(80px,240px) minmax(150px,1fr);
    grid-gap: 10px;
}
.SingleGrid{
    display: grid;
    padding: 10px;
    grid-template-columns: minmax(30px,1fr);
    grid-gap: 10px;
}
.Field input{
    background-color: #fff;
    border: 2px solid #c7c6c4;
    width: 96%;
}
.Field label{
    word-wrap: break-word;
    font-size: 16px;
    text-wrap: wrap;
    text-align: start;
    /* margin: auto; */
}

.Field input:disabled{
    background-color: #f2f2f2;
    /* font-weight: bold;
    color: black; */
}
.Field .required{
    border: 5px solid red;
}
.Field .warning{
    border: 5px solid red;
    background-color: #e68981;
}

.Field .Heading{
    color: #8B0000;
    text-align: center;
    background-color: #96d4d4;
    padding: 10px;
    margin: 0;
    height: 100%;
}
.Field .Button{
    width: fit-content;
    border-radius: 10px;
    padding: 10px;
    font-size: larger;
    font-weight: 900;
    background-color: #96d4d4;
}

.Field .Submit{
    width: fit-content;
    border-radius: 10px;
    padding: 10px 80px;
    font-size: larger;
    font-weight: 900;
    background-color: #96d4d4;
}
.Field .Submit:hover{
    background-color: #005b9d;
    border-radius: 10px;
    color: #fff;
    border: 2px solid #fff;
}

.Field .Icon{
    width: 10%;
    height: 23px;
    border-radius: 10px;
    padding: 0px;
    font-size:small;
    font-weight: 100;
    background-color: #f3e7e7;
}
.Field .Icon:hover{
    background-color: #96d4d4;
    color: #fff;
    border: 2px solid #fff;
}
.Field .DivButton{
    padding: 10px;
    display: flex;
    justify-content: center;
}
.singleFlex{
    display: flex;
    align-items: center;
    justify-content: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Field textarea:disabled{
    background-color: #f2f2f2;
    /* font-weight: bold;
    color: black; */
}

