.form-4MReport-Create{
  max-width: 100%;
  /* margin: 0 auto; */
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
  
}

.form-4MReport-Edit,
.form-4MReport-View{
  width: 100%;
 /* height: 270%; */
  margin: 0 auto;
  padding: 50px;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
}
.topic-head{
  font-weight: bold;
  font-family: "Calibri";
}
.form-4MReport-Edit input[type="text"],
.form-4MReport-Edit input[type="number"],
.form-4MReport-Create input[type="text"],
.form-4MReport-Create input[type="number"] {
margin: 2px;
display: block;
width: 95%;
height: 30px;
padding: 10px;
font-size: 16px;
border: none;
border-radius: 5px;
background-color: #fff;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
box-sizing: border-box;
}

.form-4MReport-Create textarea{
margin: 5px;
padding: 10px;
display: block;
width: 95%;
border: none;
border-radius: 5px;
background-color: #fff;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
box-sizing: border-box;
}

.table-cell,
#mlcss-view,
#mlcss-edit {
font-size: 12px;
border-spacing: 0;
padding-top: 0px;
padding-left: 0px;
padding-bottom: 0px;
padding: 5px;
margin: 0;
width: 100px;
white-space: nowrap;
font-weight: bold; 
}
.table-list-head{
width: 5%;
}
.cell {
height: 50%;
white-space: nowrap;
padding: 10px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes gradient {
  0% {
    color: #6f6c6c;
  }
  50% {
    color: #9e9e9e;
  }
  100% {
    color: #6f6c6c;
  }
}

.load {
  text-align: center; 
  font-weight: bold;  
  color: #6f6c6c;
  opacity: 1;       
  transition: opacity 0.5s ease-in-out;
  animation: pulse 1.5s infinite, gradient 3s infinite; 
  font-size: 1.2rem; 
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); 
}
.Employee_name{
  text-align: center;
}
.Received{
  width: 100% !important;
  

}