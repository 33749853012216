.QaLayoutPaint button {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid #96d4d4;
  border-left: 1px solid #96d4d4;
  border-right: 1px solid #96d4d4;
  border-bottom: 0px solid #96d4d4;

  background-color: white;
  padding: 5px 20px;
  font-weight: 600;
  font-size: larger;
  margin-bottom: 10px;
}
.QaLayoutPaint .selected {
  background-color: #5abfbf;
  border-top: 2px solid #005c9f;
  border-left: 2px solid #005c9f;
  border-right: 2px solid #005c9f;
  border-bottom: 0px solid #005c9f;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.QaLayoutPaint .unselected {
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.QaLayoutPaint button:hover {
  background-color: #5abfbf;
  border-top: 2px solid #005c9f;
  border-left: 2px solid #005c9f;
  border-right: 2px solid #005c9f;
  border-bottom: 0px solid #005c9f;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.QaLayoutPaint .back:hover {
    background-color: white;
  }

  .QaLayoutPaint .back {
    background-color: #3283bc;
    border: 2px solid #052b46;
    border-radius: 10px;
    height: 45px;
  }
  .QaLayoutPaint .approvebtn {
    background-color: #3283bc;
    border: 2px solid #052b46;
    border-radius: 10px;
    height: 45px;
  }.QaLayoutPaint .rejectbtn {
    
    background-color: #3283bc;
    border: 2px solid #052b46;
    border-radius: 10px;
    height: 45px;
  }
  .QaLayoutPaint .actions {
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
    margin-right: 1%;
    gap: 5%;
  }
  .QaLayoutPaint .formhead{
    display: flex;
    flex-direction: row;
    justify-content: space-between ;
    width: 100%;
  }
  .ApprovalRemarks{
    width: 920PX;
    text-wrap: wrap;
    word-wrap: break-word;
    padding-right: 5PX;
    height: auto;
    
  }
  .ApprovalRemarks .reject{
    background-color: #d48181;
    border-radius: 5px;
    padding: 10px;
  }
  .ApprovalRemarks .approve{
    background-color: #b3e6a1;
  }
  .QaLayoutPaint{
    width: 100%;
  }
  .Pdf{
    display: flex;
    justify-content: center;
    margin-left: 80%;
    font-size: 11;
    width: 20%;
    color: rgb(40, 135, 64);
    margin-bottom: auto; 
    
    }