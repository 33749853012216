.form-mcareq-Create{
  max-width: 100%;
  /* margin: 0 auto; */
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
  
}

.form-mcareq-Edit,
.form-mcareq-View{
  max-width: 100%;
  margin: 0 auto;
  padding: 50px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;}

.topic-head{
  padding: 10px;
  font-weight: bold;
  font-family: "Calibri";
 
}
.form-mcareq-Edit input[type="text"],
.form-mcareq-Edit input[type="number"],
.form-mcareq-Create input[type="text"],
.form-mcareq-Create input[type="number"] {
margin: 2px;
display: block;
width: 95%;
height: 30px;
padding: 10px;
font-size: 16px;
border: none;
border-radius: 5px;
background-color: #fff;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
box-sizing: border-box;
}

.table-cell,
#mcareqcss-view,
#mcareqcss-edit {
font-size: 12px;
border-spacing: 0;
padding-top: 0px;
padding-left: 0px;
padding-bottom: 0px;
padding: 5px;
margin: 0;
width: 100px;
white-space: nowrap;
font-weight: bold; 
}
.table-list-head{
width: 5%;
}

.mcareq-checkbox {
  margin-top: 10px;
  width: 30px;
}

.cell {
  height: 50%;
  white-space: nowrap;
  padding: 10px;
}