.background {
  background-color: #02040f;
  width: 100%;
  height: 100vh;
  padding: 30px;

  overflow: hidden;
}
.Card {
  position: relative;
  background-color: #86bbd8;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}
.image {
  position: absolute;
  bottom: 5%;
  width: 100%;
  height: 70%;
  opacity: .6;
}
.branding {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
  width: 96%;
  box-shadow: 0px 10px 25px 5px rgba(0, 0, 0, 0.25);
}
.branding_logo {
  width: 60px;
  height: 60px;
}
.Login_card {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 10px;
  margin: 20px 20px 20px 20px;
  padding: 30px 30px 70px 30px;
  right: 30%;
  top: 18%;
  height: auto;
  width: 45%;
  font: 1em Arial;
  z-index: 1;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 10px 25px 25px 25px rgba(0, 0, 0, 0.5);
  
}
@media screen and (min-width: 880px) {
  .Login_card {
    width: 31%;
  }
}
.Login_card::before {
  content: "";
  position: absolute;
  top: -8px; /* -blur */
  left: -8px; /* -blur */
  width: calc(100% + 16px); /* 100% + blur * 2 */
  height: calc(100% + 16px); /* 100% + blur * 2 */
  background-image: linear-gradient(
    #d9cfc1,
    rgba(255, 255, 255, 0.7)
  ); /* change color or image here */
  background-position: 50%;
  filter: blur(10px);
  z-index: -1;
}
.error {
  color: red;
  font-size: 18px;
}
.welcomeMessage {
  text-align: center;
}
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.form input {
  height: 50px;
  background-color: #d9cfc1;
  border-radius: 10px;
  box-shadow: 0px 10px 25px 5px rgba(0, 0, 0, 0.25);
  font-size: x-large;
  border: 2px solid #005C9F;
}
.form button {
  width: 60%;
  height: 40px;
  font-size: larger;
}
.forget_password {
  margin-top: 20px;
  cursor: pointer;
  text-align: center;
}
.Login_header{
  text-align: center;
  font-family: auto;
  font-weight: bold;
}
