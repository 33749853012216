.form-Machbdlogo{
    border-radius: 5px;
    height: 40px;
    width: 40px;
    padding: 0;
     margin: 0;
    object-fit: contain;
   
  }
  .form-Machbd-Create{
    width: 100%;
    margin: 0 auto;
    padding: 5px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
    background-color: 0px 0px 0px ;
    
  }
  
  .form-Machbd-Edit,
  .form-Machbd-View{
    width: 100%;
    height: 160%;
    margin: 0 auto;
    padding: 40px;
    background-color: #f2f2f2;
    box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
    background-color: 0px 0px 0px ;
  }
  
  .topic-head{
    padding: 10px;
    font-weight: bold;
    font-family: "Calibri";
  
  }
  
  .form-Machbd-Edit input[type="text"],
  .form-Machbd-Edit input[type="number"],
  .form-Machbd-Create input[type="text"],
  .form-Machbd-Create input[type="number"],
  .form-Machbd-Create textarea {
  margin: 2px;
  display: block;
  width: 95%;
  height: 30px;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  }
  select {
  margin: 2px;
  display: block;
  width: 90%;
  height: 30px;
  /* padding: 10px; */
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #fac364;
  box-shadow: 0px 0px 5px rgba(184, 189, 141, 0.1);
  box-sizing: border-box;
  }
  
  .table-cell,
  #Machbdacss-view,
  #Machbdacss-edit {
  font-size: 12px;
  border-spacing: 0;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding: 5px;
  margin: 0;
  width: 100px;
  white-space: nowrap;
  font-weight: bold;
  }
  
  .Machbd-checkbox {
    margin-top: 10px;
    width: 30px;
  }
  .Machbd-radio {
    margin-top: 10px;
    width: 30px;
    padding: 10px;
  }
  
 .Machbd-list{
  width: 100%;
  padding: 2px;

 }
 .cell {
  height: 50%;
  white-space: nowrap;
  padding: 10px;
}