.outputimage{
    width:100%;
    display: flex;
    justify-content: center;
    padding: 10px 10px;
    cursor: pointer;
}
.SelectionBar{
    position: absolute;
    gap: 10px;
    padding: 10px;
    border-radius: 25px;
    left:30px;
    background-color: #96d4d4;
}
.card{
    position:relative
}
