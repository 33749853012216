.head {
  background-color: #96d4d4;
  text-align: center;
  white-space: nowrap;
  padding: 20px 0px;
}

.filter {
  background-color: #dfdff2;
  display: flex;
  width: 100%;
  padding: 10px 20px;
  gap: 10px;
}

#selector {
  width: 50%;
}

.pdfsection {
  position: relative;
  background-color: #dfdff2;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.pdfcard {
  padding: 30px ;
  display: flex;
  flex-direction: column;
  width: 550px;
  background-color: white;
  margin: auto;
}

.barcode {
  display: flex;
  width: auto;
  justify-content: center;
  padding: 5px;
}

.heading {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
}
.values {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2px;
}

.pdfhead {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

.title {
  font-size: larger;  
  font-weight: 500;
  height: 100%;
  margin: auto;
}
.bar-img{
    margin-right: 5px;
    width: 40px;
    height: 40px;
}


.printbtn {
  width: 150px;
  position: absolute;
  right: 20px;
  bottom: 30px;
}

/* @page {
  size: 80mm 40mm;
} */

@media print {
  @page {
    size: 80mm 40mm;
  }
  .pdfcard {
    /* transform: rotate(270deg); */
    background-color: white;
    margin: 0;
    padding: 10px 20px;
    width:450px;
    height: 225px;
    /* padding: 10px 30px 5px 30px; */
  }
  .pdfcard img{
    width: 20px;
    height: 20px;
  }
  .pdfcard .title{
    font-size: 16px;
  font-weight: 500;
  }
  .pdfcard .heading {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .pdfcard .values {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 1px;
  }
}


