.sidebar {
  height: 100%;
  width: 260px; 
  background: #000205;
  position: fixed;
  top: 0;
  left: -230px; 
  transition: left 0.8s ease, width 0.8s ease; 
  overflow-y: auto;
  max-height: 100vh;
  border-top-right-radius: 20px;
}

.sidebar.open {
  left: 0; 
  width: 250px; 
  
}

.toggle-button {
  position: fixed;
  top: 250px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: left 0.8s ease, width 0.8s ease; 
  /* transition:transform rotate(180deg); 
   transform: rotate(180deg); */
  
}

.toggle-button img {
  width: 35px;
  height: 35px;
  background-color: #686464;
  border-radius: 60px;
}

