.dashboard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin: 20px;
  }
  
  .box {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .box h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .box p {
    font-size: 30px;
    font-weight: bold;
  }
  