table {
  width: 100%;
  border-spacing: 0;
  border: 0 solid black !important;
  
}

.pagination {
  margin: 2rem 0;
  justify-content: center;
}

.page-button {
  height: 30px;
  width: 30px;
  border-radius: 3px;
  border-width: 1px;
  background-color: #78C4C5 !important;
  border-color: #78C4C5 !important;
  padding: 0 !important;
}

.page-button:hover, .page-button:focus {
  background-color: #78C4C5 !important;
  border-color: #78C4C5 !important;
  box-shadow: none;
}

.page-button:disabled {
  background-color: #7a8083 !important;
  border-color: #7a8083 !important;
}

.no-data {
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.table-actions-block {
  display: flex;
  float: right;
}

.table-actions-spacing {
  margin-left: 5px;
}

/* table th,
table td {
  border-right: 1 solid black !important;
  border-bottom: 1px solid #b6b6b6 !important;
  font-weight: normal;
  /* padding: 1rem 1rem 1rem 0.1rem !important; 
} */

table th {
  font-weight: 700 !important;
  font-size: 16px;
  text-align: left;
  font-weight: bolder;
}

table td {
  font-size: 14px;
  vertical-align: middle !important;
}

table th:last-child,
table td:last-child {
  padding-right: 0 !important;
}

.search-row:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.table-name {
  font-weight: 600 !important;
}

.edit {
  text-align: left;
  font-size: 13px;
  font-family: Appfont, sans-serif;
  letter-spacing: 0;
  line-height: 17px;
  border: 1px solid #b6b6b6;
  border-radius: 3px;
  background: transparent;
  color: black;
  width: 100px;
  height: 35px;
  font-weight: lighter;
}

.edit:hover {
  background-color: #e0e0e0;
  border-color: #b6b6b6;
  color: black;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #9e9999;
  border-color: #9e9999;
}

.table-button {
  float: right;
}

.list-icon {
  height: 15px;
}

.list,
.list:active,
.list:focus {
  font-size: 13px;
  font-family: Appfont, sans-serif;
  letter-spacing: 0;
  line-height: 17px;
  border: 1px solid #b6b6b6;
  border-radius: 3px;
  background: transparent;
  color: black;
  width: 40px;
  height: 35px;
  font-weight: lighter;
}

.list:hover {
  background-color: #e0e0e0;
  border-color: #b6b6b6;
  color: black;
}

.sticky-alert {
  position: sticky;
  top: 0;
}

.toast {
  width: 75% !important;
  margin-left: 10vw;
  margin-right: 10vw;
  max-width: 60vw !important;
  justify-content: center !important;
}

.toast-header {
  background-color: #78C4C5 !important;
  background-clip: unset !important;
  color: #121317;
  justify-content: center;
}

.fade.toast.sticky-alert.show {
  z-index: 1 !important;
  display: block;
  opacity: 1;
}

.last-column {
  width: 0;
}

.tenant-form {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.search-button-block {
  padding-right: 10px;
}

.field-val {
  text-align: center;
  color: red;
}

.search-button {
  background-color:#78C4C5 !important;
  border-color: #78C4C5 !important;
  color: #121317 !important;
}