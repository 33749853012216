.form-DailyShiftProcessCheckList-Create{
  max-width: 100%;
  
  /* margin: 0 auto; */
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
  overflow: auto;
  
}
.form-DailyShiftProcessCheckList-Create th{
text-align: center;
}
.form-DailyShiftProcessCheckList-List{
width: 4%;
padding: 0%;
}
.form-DailyShiftProcessCheckList-Edit,
.form-DailyShiftProcessCheckList-View{
  width: 98%;
  margin: 0 auto;
  padding: 30px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
}
.topic-head{
font-size: 15px;
font-family: "Calibri";
text-align: center;
}
.form-DailyShiftProcessCheckList-Edit input[type="text"],
.form-DailyShiftProcessCheckList-Edit input[type="number"],
.form-DailyShiftProcessCheckList-Create input[type="text"],
.form-DailyShiftProcessCheckList-Create input[type="number"] {
margin: 2px;
display: block;
width: 95%;
height: 30px;
padding: 10px;
font-size: 16px;
border: none;
border-radius: 5px;
background-color: #fff;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
box-sizing: border-box;
}

.table-cell,
#mlcss-view,
#mlcss-edit {
font-size: 12px;
border-spacing: 0;
padding-top: 0px;
padding-left: 0px;
padding-bottom: 0px;
padding: 5px;
margin: 0;
width: 100px;
white-space: nowrap;
font-weight: bold; 
}
.table-list-head{
width: 2%;
}
.cell {
height: 50%;
font-size: 12px;
white-space: nowrap;
padding: 1px;
}
.listcell {
  height: 50%;
  font-size: 12px;
  white-space: nowrap;
  padding: 0px;
  }
.Shift-dropdown{
width: 50% !important;
height: 50% !important;
margin: auto !important;
font-size:large !important;
}
.custom-button {
border-color: #8b4f4f;
width: 150px;
border-radius: 10px;
background: rgb(32, 129, 240);
color: rgb(249, 249, 249);
}
.custom-button1 {
position: fixed;
font-size:large;
width: 15%; 
margin-top: 2%;
height: 50%;
left: 0;
white-space: nowrap;
padding: 1px;
}
.container {
position: relative;
}

.topic-head1{
font-size: 12px;
font-family: "Calibri";
text-align: center;
}



