.type-radio-button {
  margin-bottom: 10px;
}

.hover-table-row:hover {
  background-color: #f1eeee;
  cursor: pointer;
}

.policy-table-width {
  width: 50%;
}

.value {
  color: grey;
  font-weight: lighter;
  padding-top: 5px;
  padding-bottom: 10px;
}

.radio-button-icon {
  margin-left: 10px;
  height: 20px;
}

.metadata-value-spacing {
  padding-bottom: 10px;
}

.metadata-value-spacing:nth-child(even) {
  padding-left: 15px;
}

.back-button-block {
  margin-bottom: 10px;
}

.back-button {
  font-size: 16px;
  font-family: Appfont, sans-serif;
  letter-spacing: -1px;
  line-height: 17px;
  border: 1.5px solid #78C4C5;
  border-radius: 5px;
  background-color: #78C4C5;
  color: #121317;
  width: 100px;
  height: 30px;
}

.value-spacing {
  color: grey;
  font-weight: lighter;
  padding-top: 5px;
  padding-bottom: 10px;
}

.search-block {
  display: flex;
  justify-content: space-between;
}

.search-heading {
  padding-top: 20px;
}

.pagination-block {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.search-spinner {
  display: flex;
  justify-content: center;
  height: 300px;
  align-items: center;
}

.view-all-block {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.view-all-button,
.view-all-button:visited {
  height: 35px;
  background: white !important;
  color: #363636 !important;
  border: solid black 1px !important;
  font-family: Appfont, sans-serif;
  line-height: 17px;
  letter-spacing: -1px;
  font-size: 16px;
}

.view-all-button:hover {
  height: 35px;
  border: solid #78C4C5 1px !important;
  font-family: Appfont, sans-serif;
  line-height: 17px;
  letter-spacing: -1px;
  font-size: 16px;
  color: #fff !important;
  background-color: #78C4C5 !important;
}

.view-all-button:disabled {
  color: black !important;
  border-color: #9e9999 !important;
  background-color: #9e9999 !important;
  cursor: not-allowed;
}

.view-all-icon {
  padding: 0 4px;
  vertical-align: bottom;
  height: 15px;
}

.table-header {
  font-weight: 700 !important;
}

.provider-icon {
  height: 20px;
  padding-right: 10px;
}

.col-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12vw, 1fr));
}

.col-box-appowner {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr));
}

.parent-details {
  color: black;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.no-records {
  color: grey;
  font-weight: lighter;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout-request-view {
  justify-content: center;
  width: 84%;
  color: #313233;
  font-weight: bold;
  margin-top: 50px;
  margin-left: 8vw;
}

.new-auth-block {
  padding-top: 10px;
}

.new-auth-details {
  margin-top: 20px;
}

.new-auth-title {
  display: flex;
}

.delete-button {
  float: right;
}

.info-modal-content {
  padding: 15px 0;
  color: white;
}

.radio-button-block {
  display: flex;
  justify-content: space-between;
}

.radio-button {
  font-weight: 400;
}

.basic-multi-select {
  margin-top: 1rem;
}

.info-flex {
  display: flex;
  align-items: baseline;
}

.info-modal {
  background: #363636;
  color: #b6b6b6;
  border-radius: 5px;
}

.info-learn-more {
  background-color: #78C4C5;
  border: 0;
  padding: 10px 15px;
  margin-right: 20px;
  color: #363636;
  font-size: 14px;
  height: 45px;
  width: 120px;
}

.info-help {
  background: #363636;
  border: 1px solid white;
  padding: 10px 15px;
  height: 45px;
  width: 120px;
  font-size: 14px;
}

.modal-content {
  border: 0;
}

.modal-body{
  overflow-y: auto;
}

.info-icon {
  margin-left: 10px;
  height: 16px;
}

.table-actions {
  width: 110px;
}

.table-content {
  vertical-align: middle !important;
}

.shopify-logo {
  height: 20px;
  width: 20px;
  vertical-align: text-bottom;
  margin-right: 10px;
}

.form-check-inline .form-check-input {
  height: 20px;
  width: 20px;
}

.field-validation-message {
  text-align: left;
  color: red;
  font-size: 1rem;
  font-weight: 400;
}

.default-check-box {
  vertical-align: middle;
  padding-top: 8px;
}

.metadata-block {
  display: flex;
  justify-content: space-between;
}

.redirecturicontainer {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.css-1wa3eu0-placeholder {
  font-weight: 100;
  color: #495057;
}

.provider-dropdown {
  height: 15px;
}

td.text-center {
  padding: 3px 0 !important;
}

.table th {
  border-top: 0 !important;
}

.table.table-hover th,
.table.table-hover td {
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.table.table-hover {
  border-collapse: collapse !important;
}

.close-icon {
  width: 2vw;
  color: white;
  float: right;
}

.ciam-pop-up {
  font-size: 16px;
  font-family: Appfont, sans-serif;
  letter-spacing: -1px;
  line-height: 17px;
  border: 1.5px solid #78C4C5;
  border-radius: 5px;
  background-color: #78C4C5;
  color: #121317;
  width: 70px;
  margin-left: 5px;
  height: 30px;
}

.ciam-modal-body {
  display: flex;
  justify-content: center;
}

.ciam-modal-button-flex {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px;
}

.state-validation {
  text-align: left;
  color: red;
  font-size: 1rem;
  font-weight: 400;
}

.css-1uccc91-single-value {
  font-weight: 100;
}

.btn.meta.focus,
.btn.meta:focus {
  outline: 0;
  border: 2px solid rgb(173 172 172 / 75%);
  border-radius: 3px;
  background: transparent;
  color: #000;
  box-shadow: none;
  height: 50px;
}

button.view-all-button.add-new.focus,
button.view-all-button.add-new:focus {
  outline: 0;
  border-radius: 3px;
  box-shadow: none;
  height: 45px;
  background: white;
  color: #363636;
  border: solid black 2px;
}

.form-group {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.policy-table-block {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.srv-validation-message {
  color: red;
  font-weight: lighter;
}

small.help-text.form-text {
  color: red;
}

.table-scroll {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.fade.toast.sticky-alert1.show {
  margin-left: 20vw;
  margin-right: 20vw;
  position: sticky;
  top: 0;
  z-index: 1 !important;
  display: block;
  opacity: 1;
}

.rejected-block {
  height: max-content;
  border: red 1px solid;
  border-radius: 4px;
  padding: 10px;
}

.rejected-block-value {
  font-weight: lighter;
}

.input-text-field{
  width: 790px;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border: 1px solid rgb(202, 195, 195);
}

.table-width{
  width: 70vw;
  overflow-x: scroll;
}

.display-flex{
  display: flex;
}