
th{
    background-color: #96d4d4;
}
.excel {
    display: flex;
    justify-content: center;
    margin-left: 80%;
    font-size: 11;
    width: 20%;
    color: black 
}
