.hover-table-row:hover {
  background-color: #f1eeee;
  cursor: pointer;
}

.policy-table-width {
  width: 50%;
}

.value {
  color: grey;
  font-weight: lighter;
  padding-top: 5px;
  padding-bottom: 10px;
}

.radio-button-icon {
  margin-left: 10px;
  height: 20px;
}

.metadata-value-spacing {
  padding-bottom: 10px;
}

.metadata-value-spacing:nth-child(even) {
  padding-left: 15px;
}

.back-button-block {
  margin-bottom: 10px;
}

.back-button {
  font-size: 16px;
  font-family: Appfont, sans-serif;
  letter-spacing: -1px;
  line-height: 17px;
  border: 1.5px solid #78C4C5;
  border-radius: 5px;
  background-color: #78C4C5;
  color: #121317;
  width: 100px;
  height: 30px;
}

.value-spacing {
  color: grey;
  font-weight: lighter;
  padding-top: 5px;
  padding-bottom: 10px;
}

.search-block {
  display: flex;
  justify-content: space-between;
}

.search-heading {
  padding-top: 20px;
}

.pagination-block {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.search-spinner {
  display: flex;
  justify-content: center;
  height: 300px;
  align-items: center;
}

.view-all-block {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.view-all-button,
.view-all-button:visited {
  height: 35px;
  background: white !important;
  color: #363636 !important;
  border: solid black 1px !important;
  font-family: Appfont, sans-serif;
  line-height: 17px;
  letter-spacing: -1px;
  font-size: 16px;
}

.view-all-button:hover {
  height: 35px;
  border: solid #78C4C5 1px !important;
  font-family: Appfont, sans-serif;
  line-height: 17px;
  letter-spacing: -1px;
  font-size: 16px;
  color: #fff !important;
  background-color: #78C4C5 !important;
}

.view-all-button:disabled {
  color: black !important;
  border-color: #9e9999 !important;
  background-color: #9e9999 !important;
  cursor: not-allowed;
}

.view-all-icon {
  padding: 0 4px;
  vertical-align: bottom;
  height: 15px;
}

.table-header {
  font-weight: 700 !important;
}

.provider-icon {
  height: 20px;
  padding-right: 10px;
}

.col-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12vw, 1fr));
}

.col-box-appowner {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr));
}

.parent-details {
  color: black;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.no-records {
  color: grey;
  font-weight: lighter;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout-request-view {
  justify-content: center;
  width: 84%;
  color: #313233;
  font-weight: bold;
  margin-top: 50px;
  margin-left: 8vw;
}

.new-auth-block {
  padding-top: 10px;
}

.new-auth-details {
  margin-top: 20px;
}

.new-auth-title {
  display: flex;
}
