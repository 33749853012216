.sidebar_container {
  background: #000205;
  width: 250px;
  height: 100%;
}


.sidebar_header {
  padding: 20px 10px;
  display: flex;
  /* justify-content: center; */
}

.sidebar_header_image {
  height: 40px;
  object-fit: contain;
}

.sidebar_heading {
  font-size: 24px;
  color: white;
  margin-left: 10px; 
  /* font-size: 1.2rem; */
  font-weight: bolder;

}

.sidebar_navigation_text {
  color: #797979;
  font-size: 12px;
  font-weight: 600;
  margin: 5px;
}

.sidebar_accordion_head_container {
  display: flex;
  padding: 0px 10px;
  padding-bottom: 5px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}

.sidebar_accordion_head_left {
  display: flex;
  color: #797979;
  gap: 15px;
  font-weight: bold;
}

.sidebar_accordion_head_icon {
  height: 25px;
  width: 25px;
  /* transition: width 2s, height 2s, transform 2s; */
  color: #797979;
}
/* .sidebar_accordion_head_icon:hover{
  transform: rotate(180deg);
} */

.sidebar_accordion_head_arrow_icon {
  height: 15px;
  width: 15px;
  transform: rotate(270deg);
}

.sidebar_collapsible_item {
  /* color: #ffffff; */
  cursor: pointer;
  padding: 0px 10px;
  padding-bottom: 5px;
  color: #b8b8b8;
  transition: background-color 0s;
  transition: transform .2s;
  font-size: 14px;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar_collapsible_item:hover {
  color: #fdfdfd;
  font-weight: bold;
  transform: scale(1.05);
  border-left: 0.25px solid white;
  /* font-size: 16px; */
  
}

.sidebar_collapsible_open {
  /* display: flex; */
  flex-direction: column;
  gap: 15px;
  padding: 0px 15px;
  padding-bottom: 5px;
}
/* .sidebar_collapsible_item {
  padding: 0px;
  cursor: pointer;
  transition: background-color 0s;
} */

.sidebar_collapsible_item.active {
  background-color: #779aab4f;
  color: #ffffff !important;
  border-radius: 5px;
  /* border: 1px solid #0ed1dc; */
  /* padding-left: 2px; */
  padding: 3px 8px;
  transform: scale(0.85);
  font-size: 17px;
  font-weight: bold;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

