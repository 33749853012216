.header_container {
  width: 100%;
  background-color: #8dcfd4;
  font-weight: bold;
  display: flex;
  padding: 1px 20px; 
  justify-content: space-between;
  }

.header_left_search {
  position: relative;
  }

.header_left_search_input {
  width: 150px;
  height: 35px;
  border-radius: 25px;
  background-color: rgba(108, 117, 125, 0.09);
  color: #6c757d;
  border: none;
  padding: 0px 10px;
  padding-right: 30px;
  }

.header_left_search_icon {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 15px;
  width: 15px;
}

.header_right_notifcations {
  position: relative;
  height: 20px;
}

.header_notifications_icon {
  height: 20px;
  width: 20px;
  }

.header_right {
  display: flex;
  align-items: center;
  gap: 20px;
  }

.header_right_notifcations_count {
  position: absolute;
  background: blueviolet;
  border-radius: 50%;
  font-size: 8px;
  height: 12px;
  width: 12px;
  padding: 1px;
  text-align: center;
  color: white;
  top: -4px;
  right: -4px;
  }

.header_right_profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header_right_profile_icon {
  height: 30px;
  width: 30px;
}

.header_right_profile_text {
  font-size: 14px;
  font-weight: 400;
}

.header_right_arrow_icon {
  height: 15px;
  width: 15px;
}


.head4{
  font-size: 25;
  font-weight: bold;
}
.img {
  margin-top: 0px;
  height: 60px;
  width: 60px;
  margin-left: 00%;
  border-color: #067ee6;
  border: 1px solid rgb(15, 14, 14);
  border-radius: 50%;
 
}

.notification-container {
  /* position: relative; */
  display: inline-block;
}

.img1 {
  text-align: center;
  background-color: rgb(201, 4, 4);
  border-radius:0px;
  margin-top: 7px;
  height: 20px;
  width: 20px;
  color: white;
  margin-right: -57px;
  margin-left: 80%;
  border-color: #e60619;
  /* border: 1px solid white;  */
  border-radius: 50%; 
  /* z-index: 1; */
  font-size: small;

  }

 .icon {
  margin-left: -18%;
  margin-top: 10px;
  height: 40px;
  width: 40px;
  width: 2;
  margin-right: 0%;
  border-radius: 100%;
  font-style: normal;
  font-size: 12px;
  color:black;
  top: 3%; 
  right: 0;
  background-color: rgb(114, 183, 192);
  border: 1px solid #ccc; 
  padding: 6px; 
  cursor: pointer;
  z-index: 1000;
  transition: border-color 0.3s;
  border: none;
  opacity: 0.7;
  filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3));
}

.icon:hover {
  border: 2px solid blue; 
}

.icon1 {
  width: 70px;
  font-size: 45px;
  margin-right: 100%;
  margin-left:230% ;
  border-radius: 50%;
}
.text{
  background-color: pink; 
  display: inline-block; 
  padding: 20px;
  border-radius: 20px;
  color: rgb(78, 78, 217);
  margin-top: 0%;
  border: 1px solid red;
  margin-left: 950%;

}
.textC{
  color: rgb(78, 78, 217);
}
.textB{
  display: inline-block; 
  color:  rgb(33, 31, 32);
}
.cont{
  margin-left: 0px;
}

.dropdown {
  position: absolute;
  margin-top: 5.1%;
  width: 30%;
  margin-right: 16%;
  margin-left: 0%;
  border-radius: 10%;
  font-style: normal;
  font-size: 12px;
  color:black;
  top: 10%; 
  right: 0;
  /* background: linear-gradient(to right, #f1d3e6, #a3e1d4, #f0b4e4,#a3e1d4); */
  border: 1px solid #d9cfc1; 
  /* box-shadow: 0 2px 4px rgba(250, 249, 249, 0.2);  */
  padding: 5px; 
  z-index: 1000;
  overflow: scroll;
  height: 65vh;
  background: #d9cfc1;  
  box-shadow: rgb(114, 112, 91) 4px 4px 10px;
}

.dropdownContent {
  padding: 10px; 
  font-size: 12px;
  color: black;
}

.separator {
  color: rgb(56, 56, 56);
  font-family:  Calibri;
  font-size: 12px;
  border: 2px solid #faf7f7;
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
  transition: border-color 0.3s;
  background-color: rgb(248, 249, 250);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.separator:hover {
  border-color: #0ddfee; 
}
.Uname {
  font: 1em Arial;
  color: black;
  margin-top: 20%;
  margin-right: -20%;
  margin-left: 0%;
  
}

.greet{
  color: rgb(103, 102, 102);
  font-size: 15px;

}

.greet1{
  color: rgb(103, 102, 102);
  font-size: 20px;

}
.smooth-hide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
}

/* .profile{
  height: 45px;
  width: 45px;
} */

.UnameLabel {
  filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3)) !important;
}
.dropdown1 {
  position: absolute;
  margin-top: 15px;
  width: 13%;
  margin-right: 3%;
  margin-left: 0%;
  border-radius: 10%;
  font-style: normal;
  font-size: 12px;
  color:black;
  top: 80%; 
  right: 0;
  border: 1px solid #05767e; 
  padding: 0px; 
  z-index: 1000;
  height: 20vh;
  background: rgba(245, 246, 246, 0.9);
  /* overflow: scroll; */
}

.dropdownContent1 {
 
  font-size: 12px;
  color: black;

}
.separator1 {
  color: rgb(7, 7, 7);
  font-family:  Calibri;
  font-size: 15px;
  border: 2px solid #faf7f7;
  margin: 3px 3px;
  padding: 0px;
  border-radius: 8px;
  transition: border-color 0.3s;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  border-bottom: 4px solid #05767e;
  
}
.separator1:hover {
  border-color: #05767e;

}
.separator2 {
  width: 75%;
  padding: 2px;
  font-family:  Calibri;
  /* border: 2px solid #57999e; */
  border-radius: 10px;
  transition: border-color 0.3s;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: border-color 0.3s;
  border: none;
  opacity: 0.9;
  filter: drop-shadow(2px 2px 2px  #8dcfd4(109, 179, 230, 0.954));
border-left: groove;
border-right: groove;
border-bottom: groove;
}
.separator2:hover {
  /* border: 2px solid#f7f8f8;  */
  /* border-top:groove; */
  /* border-left: groove gray;
  border-right: groove grey; */
  border-bottom: groove grey;

}
.ellipsis {
  color: black;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 70px;
  margin-left: 10px;
  cursor: pointer;
  border-bottom: black 1px solid;
}
.roleLabel{
  text-align: left;
  padding-left: 11%;
  opacity: 50%;
}
