.Listview .view {
    background-color: #be991d;
    border-radius: 10px;
    margin-bottom: 0px !important;
    padding: 3px 8px;
    width: 100% !important;
    color: #fff;
    font-weight: lighter;
}

.Listview .edit {
    background-color: #005c9f;
    border-radius: 10px;
    margin-bottom: 0px !important;
    padding: 3px 8px;
    width: 100% !important;
    text-align: center;
    color: #fff;
    font-weight: lighter;
}

.Listview .delete {
    background-color: #d7524b;
    border-radius: 10px;
    margin-bottom: 0px !important;
    padding: 3px 8px;
    width: 100% !important;
    color: #fff;
    font-weight: lighter;
}

.Listview .view:hover {
    background-color: #e8b409;
    border: 1px solid blue;
}

.Listview .edit:hover {
    background-color: #3a9be0;
    border: 1px solid blue;
}

.Listview .delete:hover {
    background-color: #ef2217;
    border: 1px solid blue;
}

.Listview th {
    text-align: center;
    font-weight: bolder;
    font-size: large;
}

.Listview td {
    text-align: center;
    font-weight: bold;
    font-size: small;
}
.Listview.custom-excel-button {
    color: black;
    background-color: yellow;
    border: 1px solid black;
    padding: 5px 10px;
    cursor: pointer;
  }
 