.form {
  color: #313233;
  font-weight: bold;
  margin-top: 30px;
  margin-left: 10vw;
  margin-right: 10vw;
}

.buttons-block {
  display: flex;
  justify-content: flex-end;
}

.helptext {
  padding-left: 1rem;
  color: #b6b6b6;
  font-weight: lighter;
}

.cancel-button {
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 17px;
  border: 2px solid rgb(173 172 172 / 75%);
  border-radius: 3px;
  background: transparent;
  color: #000;
  width: 100px;
  height: 50px;
}

.cancel-button-block {
  margin-top: 30px;
}

.save-button {
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 17px;
  border: 1.5px solid #78C4C5;
  border-radius: 3px;
  background-color: #78C4C5;
  color: black;
  width: 100px;
  height: 50px;
}

.save-button-block {
  padding-left: 20px;
  padding-top: 30px;
}
