
.form-machineMaster-Create,
.form-machinePartMaster-Create,
.form-pms-Create,
.form-pms-Edit,
.form-MasterHead-Create,
.form-Deviation-Create,
.form-RmMaster-Create,
.form-fgMaster-Create,
.form-customerMAster-Create,
.form-supplierMaster-Create,
.form-empMaster-Create{
  /* min-width: 1050px; */
  max-width: 100%;
  margin: 0 auto;
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: 0px 0px 0px ;
}
.form-pms-Create1,
.form-pms-View{
  max-width: 95%;
  margin: 0 auto;
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: 0px 0px 0px ;
}
.form-smp-Edit,
.form-smp-View,
.form-machineMaster-Edit,
.form-machineMaster-View,
.foemp-empMaster-View,
.foemp-empMaster-Edit,
.form-supplierMaster-View,
.form-supplierMaster-Edit,
.form-Master-customerView,
.form-customerMaster-Edit,
.form-fgMaster-View,
.form-fgMaster-Edit,
.form-RmMaster-Edit,
.form-rmMaster-View,
.form-machinePartMaster-View,
.form-machinePartMaster-Edit{
  max-width: 90%;
  margin: 0 auto;
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: 0px 0px 0px ;
}
.emp-gender {
  float: left;
}
.Req-i{
  color: red;
}


.table_view:nth-child(even){
background-color: whitesmoke;
}
.form-smp-Create
{
  min-width: 1090px;
  max-width: 1400px;
  /* margin: 0 auto; */
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  
}
.form-smp-View1 {

  max-width: 95%;
  margin: 0 auto;
  /* padding: 20px; */
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: 0px 0px 0px ;
}

/* .table_position{
  position:sticky ;
  top:0px;
} */


.form-group {
  margin-bottom: 20px;
}

td,
th {
  font-size: 10px;
  font-family: "Calibri";
}

label{
  display: block !important;
  margin-bottom: 5px;
  font-weight: bold !important; 
  font-size: 12px;
  text-align: center;
}

input[type="text"],input[type="number"],input[type="password"]
 {
  display: block;
  width: 100%;
  height: 30px;
  padding: 5px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
input:disabled{
  background-color: #d9cfc1;
}
textarea{
  display: block;
  width: 97%;
  /* height: 30px; */
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  
}
input[type="date"],input[type="time"] {
  display: block;
  width: 90%;
  height: 30px;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #c7c6c4;
  box-shadow: 0px 0px 5px rgba(25, 45, 82, 0.1);
  align-items: center;
  
}
.table_hi input[type="text"] {
  margin: 5px;
  width: 95%;
  display: block;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
}



button[type="submit"] {
  display: block;
  /* margin-top: 20px;*/
  padding: 3px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
  width: 20%;
  height: 40px;
  margin: 0 auto;
  margin-top: 10px;
}

button[type="submit"]:hover {
  background-color: #0069d9;
}
.table-cell1{
  padding: 0px !important;
}

button[type="reset"] {
  display: block;
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #dc3545;
  color: #fff;
  cursor: pointer;
}

button[type="reset"]:hover {
  background-color: #c82333;
}

button[type="button"] {
  display: block;
  /* margin-top: 20px;*/
  padding: 3px;
  font-size: 15px;
  border: none;
  border-radius: 10px;
  background-color: #0a8df8;
  color: #fff;
  cursor: pointer;
  width: 100%;
}

button[type="button"]:hover {
  background-color: #0b325c;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  font-size: 10px;
  height: 0.5px;
  text-align: left;
}

th {
  background-color: #96d4d4;
}

.table-code {
  font-size: 12px;
  border-spacing: 0;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding: 0;
  margin: 0;
  width: 100px;
  background-color: #ebebeb;
  
}

.table-cell {
  font-size: 12px;
  border-spacing: 0;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding: 5px;
  margin: 0;
  width: 100px;
  font-weight: bold;
}
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-inner {
  position: relative;
  padding: 0px;
  width: 100%;
  height: 100%;
  scroll-snap-type: y mandatory;
  background-color: #fcfbfb;

}
.pagination {
  display: flex;
  z-index: 1;
}
.popup-inner .close-btn {
  position: absolute;
  top: 0px;
  z-index: 20;
  right: 30px;
  width: 45px;
  height: 45px;
  background-color: #bbb8b4;
}
.table th,
.table td {
  border-right: 1px solid black !important;
  border-bottom: 1px solid #b6b6b6 !important;
  font-weight: normal;

  /* padding: 1rem 1rem 1rem 0.1rem !important;  */
  width: 100px;
}

.Layout_layout_main_area_container__1axYh {
  padding: 0px;
}

.table_hi {
  border-right: 1px solid black !important;
  border-bottom: 1px solid #b6b6b6 !important;
  font-weight: normal;
  white-space: nowrap;
  /* border-spacing: 10px 20px; */
  /* padding: 1rem 1rem 1rem 0.1rem !important;  */
  /* width: 100px; */
}
.table-cell1 {
  font-size: 12px;
  padding: 5px;
  border-spacing: 0;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding: 5px;
  margin: 0;
  width: 100px;
  white-space: nowrap;
  font-weight: bold;
  text-align: center;
}
.highlighted {
  background-color: rgb(244, 15, 15);
}

@media print {
  #smp-download,.close-btn,#pms-download,.SmpDownload{
    display: none;
  }
  .Sarathnicky{
    display: none;
  }
}
.swal-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}
.swal-button{
  background-color: #0069d9;
}
.alert {
  background-color: #82fa88;
  padding: 10px;
  border: 1px solid #cccccc;
  margin-top: 10px;
  position: relative;
}

.custom-radio {  
  width: 15px;
  height: 15px;
  /* margin-right: 10px; */
  margin-left: 2px;  
}
.Req{
  color: red;
  padding:20px;
  font-size: 20px;
}
.Pdf{
display: flex;
justify-content: center;
margin-left: 80%;
font-size: 11;
width: 20%;
color: black;
margin-bottom: auto; 
}
.table1
 {
  border: 1px solid black;
  border-collapse: collapse;
  font-size: 10px;
  height: 0.5px;
  text-align: left;
  display: none;
}
.css-13cymwt-control{
  width: 95%;
  /* background-color: #fac364 !important; */
  
}

/* .css-t3ipsp-control{
  background-color: #fac364 !important;
} */