.form-MldUnloadLoad-Create{
    max-width: 100%;
    /* margin: 0 auto; */
    padding: 5px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
    background-color: 0px 0px 0px ;
    
}
.form-MldUnloadLoad-Create th,
.form-MldUnloadLoad-View th,
.form-MldUnloadLoad-Edit th{
text-align: center;
}

.form-MldUnloadLoad-View{
  width: 95%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
}
.form-MldUnloadLoad-Edit{
  width: 95%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
}
.topic-head{
    font-weight: bold;
    font-family: "Calibri";
    padding-left: 5px;
}
.form-MldUnloadLoad-Edit input[type="text"],
.form-MldUnloadLoad-Edit input[type="number"],
.form-MldUnloadLoad-Create input[type="text"],
.form-MldUnloadLoad-Create input[type="number"] {
  margin: 2px;
  display: block;
  width: 95%;
  height: 30px;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.form-MldUnloadLoad-Create textarea{
  margin: 5px;
  padding: 10px;
  display: block;
  width: 95%;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.table-cell,
#mlcss-view,
#mlcss-edit {
  font-size: 12px;
  border-spacing: 0;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding: 5px;
  margin: 0;
  width: 100px;
  white-space: nowrap;
  font-weight: bold; 
}
.table-list-head{
  width: 5%;
}
.cell {
  height: 50%;
  white-space: nowrap;
  padding: 10px;
}
.topic-head-number{
  font-weight: bold;
    font-family: "Calibri";
    text-align: center;
}