.Form{
    max-width: 97%;
    padding: 5px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
    background-color: 0px 0px 0px ;
    
}
th{
    background-color: #96d4d4;
}
.excel {
    display: flex;
    justify-content: center;
    margin-left: 72%;
    font-size: 11;
    width: 20%;
    color: black 
}