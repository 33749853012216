.form-ProcessValidationAndReValidation-Create{
    max-width: 98%;
    /* margin: 0 auto; */
    padding: 5px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
    background-color: 0px 0px 0px ;
    
    
}

.form-ProcessValidationAndReValidation-Edit,
.form-ProcessValidationAndReValidation-View{
    width: 90%;
    margin: 0 auto;
    padding: 5px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
    background-color: 0px 0px 0px ;
}
.topic-head{
    font-weight: bold;
    font-family: "Calibri";
}
.form-ProcessValidationAndReValidation-Edit input[type="text"],
.form-ProcessValidationAndReValidation-Edit input[type="number"],
.form-ProcessValidationAndReValidation-Create input[type="text"],
.form-ProcessValidationAndReValidation-Create input[type="number"] {
  margin: 2px;
  display: block;
  width: 95%;
  height: 30px;
  padding: 5px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
/* 
.table-cell,
.table-cell td{
  font-size: 12px;
  border-spacing: 0;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding: 5px !important;
  margin: 0;
  width: 100px;
  white-space: nowrap;
  font-weight: bold; 
} */

#ProcessValidationAndReValidationview,
#ProcessValidationAndReValidationedit {
    padding: 0px;
}
.table-list-head{
  width: 5%;
}
.topic-head-trail{
    padding: 0px !important;
    padding-left: 5px !important;
}
.cell {
  height: 50%;
  white-space: nowrap;
  padding: 10px;
}
.form-ProcessValidationAndReValidation-Create th,
.form-ProcessValidationAndReValidation-Edit th,
.form-ProcessValidationAndReValidation-View th{
  text-align: center;
}