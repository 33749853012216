.form-lumpsmonlogo{
  border-radius: 5px;
  height: 40px;
  width: 80px;
  padding: 0;
   margin: 0;
  object-fit: contain;
 
}
.form-lumpsmon-Create{
  width: 100%;
  /* margin-left: 80px; */
  /* margin: 0 auto; */
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
  /* overflow: auto; */
  
}

.form-lumpsmon-Edit,
.form-lumpsmon-View{
  width: 50%;
  margin: 0 auto;
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
}

.topic-head{
  padding: 10px;
  font-weight: bold;
  font-family: "Calibri";

}

.form-lumpsmon-Edit input[type="text"],
.form-lumpsmon-Edit input[type="number"],
.form-lumpsmon-Create input[type="text"],
.form-lumpsmon-Create input[type="number"],
.form-lumpsmon-Create textarea {
margin: 2px;
display: block;
width: 95%;
height: 30px;
padding: 10px;
font-size: 16px;
border: none;
border-radius: 5px;
background-color: #fff;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
box-sizing: border-box;
}
select {
margin: 2px;
display: block;
width: 90%;
height: 30px;
/* padding: 10px; */
font-size: 16px;
border: none;
border-radius: 5px;
background-color: #fac364;
box-shadow: 0px 0px 5px rgba(184, 189, 141, 0.1);
box-sizing: border-box;
}

.table-cell,
#mcacss-view,
#mcacss-edit {
font-size: 12px;
border-spacing: 0;
padding-top: 0px;
padding-left: 0px;
padding-bottom: 0px;
padding: 5px;
margin: 0;
width: 100px;
white-space: nowrap;
font-weight: bold;
}

.mold-checkbox {
  margin-top: 10px;
  width: 30px;
}

.cell {
  height: 50%;
  white-space: nowrap;
  padding: 10px;
}
.custom-button {

  border-color: #f2f2f2;
  width: 150px;
  border-radius: 8px;
  background: rgb(32, 129, 240);
  color: rgb(249, 249, 249);
}
.custom-button1 {
  font-size:large;
  width: 15%;
  height: 100%; 
  position: absolute;
  top: 120px;
 margin-left: 1000px;
 
}