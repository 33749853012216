
.form-Deviation-Create{
    width: 100%;
    /* margin: 0 auto; */
    padding: 5px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
    background-color: 0px 0px 0px ;
    
}

.form-Deviation-Edit,
.form-Deviation-View{
    width: 85%;
    /* height: 170%; */
    margin: 0 auto;
    padding: 5px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
    background-color: 0px 0px 0px ;
}

.topic-head{
    padding: 10px;
    font-weight: bold;
    font-family: "Calibri";
   /* text-align: left; */
}

.form-Deviation-Edit input[type="text"],
.form-Deviation-Edit input[type="number"],
.form-Deviation-Create input[type="text"],
.form-Deviation-Create input[type="number"] {
  margin: 2px;
  display: block;
  width: 95%;
  height: 30px;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
select {
  margin: 2px;
  display: block;
  width: 90%;
  height: 30px;
  /* padding: 10px; */
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #fac364;
  box-shadow: 0px 0px 5px rgba(184, 189, 141, 0.1);
  box-sizing: border-box;
  cursor: pointer;
}


.table-cell,
#dfcss-view,
#dfcss-edit {
  font-size: 12px;
  border-spacing: 0;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding: 5px;
  margin: 0;
  width: 100px;
  white-space: nowrap;
  font-weight: bold;
}

.detele-button{
  background-color:red !important;

}
.detele-button:hover{
  background-color:rgb(85, 20, 20) ;  
}
.form-Deviation-Create textarea{
  margin: 2px;
  display: block;
  width: 95%;
  height: 50px;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

