.gif-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-page-text {
  text-align: center;
}

.buttons-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

.redirect-buttons {
  margin: 10px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #78C4C5;
  border-color: #78C4C5;
}
