.form-EcnReport-Create{
    max-width: 100%;
    /* margin: 0 auto; */
    padding: 5px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
    background-color: 0px 0px 0px ;
    
}

.form-EcnReport-Edit,
.form-EcnReport-View{
    width: 98%;
    margin: 0 auto;
    padding: 30px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
    background-color: 0px 0px 0px ;
}
.topic-head{
    font-weight: bold;
    font-family: "Calibri";
    padding-left: 2px;
}
.form-EcnReport-Edit input[type="text"],
.form-EcnReport-Edit input[type="number"],
.form-EcnReport-Create input[type="text"],
.form-EcnReport-Create input[type="number"] {
  margin: 2px;
  display: block;
  width: 95%;
  height: 30px;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.table-cell,
#mlcss-view,
#mlcss-edit {
  font-size: 12px;
  border-spacing: 0;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding: 5px;
  margin: 0;
  width: 100px;
  white-space: nowrap;
  font-weight: bold; 
}
.table-list-head{
  width: 5%;
}
.cell {
  height: 50%;
  white-space: nowrap;
  padding: 10px;
}
.th-head{
  height: 42px;
}
.form-EcnReport-Create th,

.form-EcnReport-Edit th,
.form-EcnReport-View th {
  text-align: center;
}
@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}