
.form-MaterialLoading-Create{
  width: 100%;
  /* margin: 0 auto; */
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
  /* height: 100%; */
  /* overflow-y: scroll; */
  
}
.printPDF {
  position:absolute;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(
    
    rgba(255, 255, 255, 0.8),#d9cfc1
  ); /* change color or image here */
  background-position: 50%;
  filter: blur(1px);
  width:100%;
  height:100vh;
  top:0;
  left:0;
}
.printpage{
  position:absolute;
  padding: 15px 8px 15px 8px;
  width: 1080px;
  height: 1920px;
  top: -25%;
  left:20%;
  transform: scale(60%);
  background-color: white;
}
.downloadbtn{
  position:absolute;
  bottom: 3%;
  right: 25%;
}
.downloadbtn button{
  padding: 10px 20px;
}
@media print {
  .printpage{
    background-color: yellow;
  }
}
/* .form-MaterialLoading-Edit,
.form-MaterialLoading-View{
width: 100%;
height: 100%;
margin: 0 auto;
padding: 50px;
background-color: #f2f2f2;
box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
background-color: 0px 0px 0px ;

  /* width: 50%;
  margin: 0 auto;
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
  background-color: 0px 0px 0px ;
  overflow: hidden; 
} */

.form-MaterialLoading-Edit,
.form-MaterialLoading-View {

width: 70%;
height: auto;
margin: 0 auto;
/* padding: 5px; */
background-color: #f2f2f2;
border-radius: 5px;
box-shadow: 0px 0px 10px rgba(43, 42, 42, 0.2);
overflow: hidden; 
}


.form-MaterialLoading-Edit::-webkit-scrollbar,
.form-MaterialLoading-View::-webkit-scrollbar {
width: 0; 
height: 0; 
}


.table .topic-head{
  font-weight: bold;
  font-family: "Calibri";
}

.form-MaterialLoading-Edit input[type="text"],
.form-MaterialLoading-Edit input[type="number"],
.form-MaterialLoading-Create input[type="text"],
.form-MaterialLoading-Create input[type="number"]
{
margin: 2px;
display: block;
width: 95%;
height: 30px;
padding: 10px;
font-size: 16px;
border: none;
border-radius: 5px;
background-color: #fff;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
box-sizing: border-box;
}
select {
margin: 2px;
display: block;
width: 90%;
height: 30px;
/* padding: 10px; */
font-size: 16px;
border: none;
border-radius: 5px;
background-color: #fac364;
box-shadow: 0px 0px 5px rgba(184, 189, 141, 0.1);
box-sizing: border-box;
}

.table-cell,
#mlcss-view,
#mlcss-edit {
font-size: 12px;
border-spacing: 0;
padding-top: 0px;
padding-left: 0px;
padding-bottom: 0px;
padding: 5px;
margin: 0;
width: 100px;
white-space: nowrap;
font-weight: bold;
text-align: center;
}

.materialLoadingSubmit { 
border-color: #f2f2f2;
border: none !important;
} 
.cell {
height: 50%;
white-space: nowrap;
padding: 10px;
}
.table-cell1{
padding: 20px;
width: 100px;
}
.table-cell2{
text-align: center;
width: 100px;
padding: 20px;
}
.table-cell3{
padding: 30px;
width: 100px;
}
.text1{
color:red;
} 
.Req{
color: red;
padding:10px;
font-size: 15px;
}
.Req-i{
color: red;
padding:10px;
font-size: 18px;
}
.custom-button {
border-color: #8b4f4f;
width: 150px;
border-radius: 10px;
background: rgb(32, 129, 240);
color: rgb(249, 249, 249);
}
.custom-button1 {
position: fixed;
font-size:large;
width: 15%; 
margin-top: 2%;
height: 50%;
left: 0;
white-space: nowrap;
padding: 1px;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: #fafafa;
  padding: 1px;
}
.grid-item-red {
  background-color: #f6ae2d;
  border: 1px solid rgba(0, 0, 0, 0.8);
  margin: 1px;
  padding: 0px;
  font-size: 12px;
  text-align: center;
  border-radius: 100%;
  font-weight: bold;
}
.grid-item-green {
  background-color: rgb(90, 244, 73);
  border: 1px solid rgba(0, 0, 0, 0.8);
  margin: 1px;
  padding: 0px;
  font-size: 12px;
  text-align: center;
  border-radius: 100%;
  font-weight: bold;
}