.icon {
    Color: #cba423;
    padding: 1px 5px;
    height: 30px;

    font-style: normal;
    font-size: 12px;
    padding: 5px;
  }
  .icon1 {
    Color: #06802f;
    margin-left: 0%;
    margin-top: 0px;
    height: 30px;
    width: 15px; 
    margin-right: 0%;
    border-radius: 10%;
    font-style: normal;
    font-size: 12px;
  }
  .icon3 {
    Color: #9d080f;
    margin-left: 0%;
    margin-top: 0px;
    height: 30px;
    width: 15px; 
    margin-right: 0%;
    border-radius: 10%;
    font-style: normal;
    font-size: 12px;
  }
  
  .ApprovalScreen{
    padding: 0px 10px;
  }
  .TableView{
    padding: 10px 20px;
  }

  .ApprovalScreen .row{
    padding: 5px 0px;
    border-bottom: 2px solid #cccccc;
  }

  .DropdownData select{
    background-color: azure;
    padding: 0px 10px;
    width: 240px;
  }
  .DropdownData select option{
    text-align: start;
  }

  .type select{
    background-color: azure;
    padding: 0px 10px;
    width: 240px;
  }
  .type select option{
    text-align: start;
  }

  .Selector select{
    background-color: azure;
    padding: 0px 10px;
    width: 240px;
  }
  .Selector select option{
    text-align: start;
  }
  
  .ApprovalScreen .table_position th{
    background-color: #c9c1c1;
    padding: 10px;
    font-size: large;
  }
  