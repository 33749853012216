.gridOverView {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    height: 50%;
}

/* .gridSize {
    padding: 5px;
} */

.gridSize2 {
    grid-column: span 3;
    /* background-color: black; */
    border: 1px solid;
    border-top: 0px;
    /* height: 60%; */
    overflow-y: scroll;
}

.gridSize1 {
    grid-column: span 1;
    /* background-color: aqua; */
    border: 1px solid;
    overflow-y: scroll;
    border-top: 0px;
    /* height: 60%; */
    border-right: 0px;
}

.SmpField {
    padding: 0px !important;
    grid-template-columns: minmax(80px, 148px) minmax(150px, 1fr);
}

/* .css-qbdosj-Input {
    margin: 0px !important;
    padding-bottom: 0px !important;
} */
.smpLabelField {
    margin: auto;
}

.fieldSingleGrid {
    padding: 0px !important;
}

.DivButton .cusbtn {
    background-color: black;
}

.SmpMaininput {
    background-color: #fff !important;
    border: 2px solid #c7c6c4 !important;
    width: 50% !important;
}

.MinusPlus {
    font-size: large;
}

.previewLabel {
    display: grid;
    grid-template-columns: 45% 55%;
    padding-left: 5px;
}

.previewLabelRight {
    font-weight: bold;
    color: black;
}
.previewLabelValue {
    font-weight: bold;
    color: black;
   text-align: center;
}
.previewHeading {
    /* font-weight: bold; */
    padding-left: 5px;
}

/* @keyframes blink {
    50% {
      opacity: 0;
    }
  }
   */
.nextButtonLabel {
    padding: 1px 10px !important;
    margin-bottom: 0px !important;
    font-size: small !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 17px !important;
    border-top-right-radius: 20px !important;
    background-color: #d9e5ff !important;
    border: solid 0.5px transparent !important;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
    /* animation: blink 1.5s infinite !important; */
}